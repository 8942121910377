<script setup>
import { useRuntimeConfig } from '#imports';
import { useAuth } from '@/composables/use-auth';
import { usePersonalDetails } from '@/composables/use-personal-details';
import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import GenericPanel from '~/components/commons/GenericPanel.vue';

const { currentUser, getToken } = useAuth();
const { getItem: getPersonalDetails }= usePersonalDetails();
const uid = computed(() => currentUser.value?.uid);
const personalDetails = ref({});
const picURL = ref('');

const isLoggedIn = computed(() => !!currentUser.value);
const accountType = computed(() => currentUser.value?.apps?.[0]);
const isPatient = computed(() => currentUser.value?.apps?.includes('parmazip-patient'));
const isPharmacist = computed(() => currentUser.value?.apps?.includes('parmazip-pharmacist'));
const isPharmacy = computed(() => currentUser.value?.apps?.includes('parmazip-pharmacy'));

onMounted(async () => {
  personalDetails.value = await getPersonalDetails(uid.value);
  picURL.value = personalDetails.value;
});

const router = useRouter();
const runtimeConfig = useRuntimeConfig();
const redirectsMap = {
  'parmazip-patient': runtimeConfig.public.patientRedirectUrl,
  'parmazip-pharmacist': runtimeConfig.public.pharmacistRedirectUrl,
  'parmazip-pharmacy': runtimeConfig.public.pharmacyRedirectUrl,
};
async function onGoToDashboard () {
  if (isPatient.value) {
    return router.push({ name: 'dashboard-patient' });
  } 
  
  if (isPharmacy.value) {
    return router.push({ name: 'dashboard-pharmacy' });
  } 
  
  if (isPharmacist.value) {
    const redirectUrl = redirectsMap[accountType.value];
    const rurl = new URL(redirectUrl);
    const accessToken = await getToken();
    rurl.searchParams.set('token', accessToken);
    console.warn('redirecting to', rurl.href);
    globalThis.location.href = rurl.href;  
  }
}
</script>

<template>
  <div data-theme="parmazip" class="bg-white">
    <!-- HEADER -->
    <GenericPanel class="!py-0">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-4">
          <a rel="noopener noreferrer" href="/" aria-label="Back to homepage" class="flex items-center">
            <img src="../assets/images/parmazip-logo-long.png" alt="Parmazip Logo" class="object-contain w-[140px] lg:w-[160px]" />
          </a>
        </div>
        <div class="flex items-center gap-4">
          <nuxt-link
            v-if="$route.name === 'patients'"
            to="/"
            class="btn btn-sm btn-ghost lg:btn-md btn-primary"
          >
            For Pharmacies & Pharmacists
          </nuxt-link>
          <nuxt-link
            v-if="$route.name === 'index'"
            to="/patients"
            class="btn btn-sm btn-ghost lg:btn-md btn-primary"
          >
            For Patients
          </nuxt-link>
          <nuxt-link
            v-if="!isLoggedIn || isPharmacist"
            to="/login" 
            class="btn btn-sm lg:btn-md btn-primary text-white w-[100px]"
          >
            Log In
          </nuxt-link>
          <button
            v-if="isLoggedIn && !isPharmacist"
            class="btn btn-sm lg:btn-md btn-primary text-white"
            @click="onGoToDashboard"
          >
            Dashboard
          </button>
          <!-- <nuxt-link
            v-if="$route.name === 'index'" 
            to="/signup/pharmacist/account" 
            class="btn btn-sm lg:btn-md btn-primary text-white"
          >
            Pharmacist Sign Up
          </nuxt-link>
          <nuxt-link 
            v-if="$route.name === 'patients'"
            to="/signup/patient/account"
            class="btn btn-sm lg:btn-md btn-primary text-white"
          >
            Patient Sign Up
          </nuxt-link> -->
        </div>
      </div>
    </GenericPanel>
    
    <slot />
    
    <!-- FOOTER -->
    <footer class="bg-[#3A474E]">
      <GenericPanel>
        <div class="flex flex-col lg:flex-row lg:items-center justify-between">
          
          <div>
            <a rel="noopener noreferrer" href="/" aria-label="Back to homepage" class="flex items-center">
              <img src="../assets/images/parmazip-logo-white.png" alt="Parmazip Logo" class="object-contain w-[260px]" />
            </a>
            <p class="text-white font-general text-xl mt-[-25px] mb-[25px]">Tech innovations for more accessible medications</p>
          </div>

          <ul class="text-2xl font-primary flex flex-col gap-4 text-white">

            <li>
              <a href="/terms-of-use" noopener noreferrer>
                TERMS OF USE
              </a>
            </li>
            
            <li>
              <a href="/privacy-policy" noopener noreferrer>
                PRIVACY POLICY
              </a>
            </li>

            <!-- <li>
              <a href="https://blog.parmazip.com/tag/helpdesk/" target="_blank" noopener noreferrer>
                FAQ
              </a>
            </li> -->
            
            <li>
              <a href="https://blog.parmazip.com/" target="_blank" noopener noreferrer>
                BLOG
              </a>
            </li>
            <li>
              <a href="https://blog.parmazip.com/about/" target="_blank" noopener noreferrer>
                ABOUT US
              </a>
            </li>
            <li>
              <nuxt-link to="/our-journey" noopener noreferrer>
                OUR JOURNEY
              </nuxt-link>
            </li>
            <!-- <li>
              <a href="" target="_blank" noopener noreferrer>
                CONTACT US
              </a>
            </li> -->
          </ul>
        </div>

        <div class="flex flex-col lg:flex-row items-center gap-8 mt-20">
          
          <div class="w-full">
            <div class="w-full h-[2px] bg-white relative hidden lg:inline-block"></div>
            <p class="text-white lg:absolute text-center lg:text-left">Copyright 2024. Parmazip Inc. All Rights Reserved.</p>
          </div>

          <nav class="flex gap-4 lg:items-center text-white">
            <a href="https://twitter.com/ParmaZip" target="blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current">
                <path
                  d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
            <a href="https://www.facebook.com/parmazip.ca" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current">
                <path
                  d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/parmazip/" target="_blank" rel="noopener noreferrer">
              <i class="lab la-linkedin text-3xl"></i>
            </a>
          </nav>
        </div>

        <!-- <div class="flex flex-col gap-2">
          <div class="text-sm text-center">
            © {{ new Date().getFullYear() }} Parmazip. All rights reserved.
          </div>
          <div class="flex justify-center items-center gap-2">
            <a href="/privacy-policy" class="text-sm text-center text-primary">Privacy Policy</a>
            <span class="text-xs">•</span>
            <a href="/terms-of-use" class="text-sm text-center text-primary">Terms of Use</a>
          </div>
        </div> -->
      </GenericPanel>
    </footer>
  </div>
</template>

<!-- Removed the <script> section entirely as it's no longer needed for fixed theme -->
